<template>
  <div class="wrap">
    <div class="banner">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">合作关系</p>
      </div>
    </div>
    <div class="inner">
      <div class="img-list">
        <ul>
          <li><img src="../../assets/images/hz1.png" /></li>
          <li><img src="../../assets/images/hz2.png" /></li>
          <li><img src="../../assets/images/hz3.png" /></li>
          <li><img src="../../assets/images/hz4.png" /></li>
          <li><img src="../../assets/images/hz5.png" /></li>
          <li><img src="../../assets/images/hz6.png" /></li>
          <li><img src="../../assets/images/hz7.png" /></li>
          <li><img src="../../assets/images/hz8.png" /></li>
          <li><img src="../../assets/images/hz9.png" /></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Customers'
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 444px;
  margin-top: 76px;
  background: url("../../assets/images/banner1.png") no-repeat center;
  overflow: hidden;
  text-align: center;
  .text {
    display: inline-block;
    margin: 117px auto 0;
  }
  .icon {
    width: 105px;
    height: 105px;
    margin: 0 auto;
    background: url("../../assets/images/banner1-icon.png") no-repeat center;
  }
  .h2 {
    font-size: 30px;
    color: #fff;
    margin-top: 62px;
  }
}
.img-list {
  margin: 17px 0 180px;
  overflow: hidden;
  ul {
    width: 1300px;
    overflow: hidden;
  }
  li {
    width: 238px;
    height: 85px;
    border: 1px solid #E5E5E5;
    margin: 76px 82px 0 0;
    float: left;
  }
}
</style>
